<template>
  <div class="login">
    <v-row style="margin-top:120px;">
      <v-spacer />
      <v-col cols="10" style="text-align:center;font-size:36px;">
        <img alt="logo" style="width:260px;" src="../assets/logo.png">
        <v-text-field
          id="account"
          v-model="account"
          outlined
          prepend-inner-icon="mdi-email-outline"
          style="width:300px;margin:20px auto 0px auto;"
          color="#4DBCB8"
        ></v-text-field>
        <v-text-field
          id="password"
          v-model="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          prepend-inner-icon="mdi-lock"
          outlined
          style="width:300px;margin:0px auto 0px auto;padding:0px"
          color="#4DBCB8"
          @click:append="show1 = !show1"
          @keyup.enter="Login"
        ></v-text-field>
        <v-btn
          outlined
          width="300"
          height="56"
          color="#4DBCB8"
          style="margin:auto;max-width:100%"
          @click="Login"
        >
          登入
        </v-btn>
      </v-col>
      <v-spacer />
    </v-row>
  </div>
</template>

<script>
import api from '../api/account';

export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      account: '',
      show1: false,
      password: '',
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 3 || 'Min 3 characters',
        emailMatch: () => ('The email and password you entered do not match'),
      },
      // search: '',
      // headers: [
      //   {
      //     text: 'Unit',
      //     value: 'unit',
      //   },
      //   {
      //     text: 'Status',
      //     value: 'status',
      //   },
      //   {
      //     text: 'Date',
      //     value: 'date',
      //   },
      // ],
      // sheets: [
      //   {
      //     unit: '業務部',
      //     status: '作業中',
      //     date: '2021/11/08',
      //   },
      // ],
    };
  },
  mounted() {
  },
  methods: {
    Login() {
      api.login({ account: this.account, password: this.password })
        .then(async ({ data }) => {
          if (data.success) {
            console.log(data, '^^^^');
            localStorage.setItem('token', data.token);
            localStorage.setItem('position', data.position.value);
            localStorage.setItem('name', data.name);
            localStorage.setItem('email', data.email);
            localStorage.setItem('id', data.id);
            localStorage.setItem('image', data.image);
            localStorage.setItem('region', data.region);
            // const checkAbility = data.ability.length;
            if (data.ability !== undefined && data.ability.length > 0) {
              localStorage.setItem('ability', data.ability);
              // this.$store.state.sideBar.splice(0, 0, {
              //   name: 'mobile',
              //   label: '施作',
              //   url: '/mobile',
              //   icon: 'mdi-account-wrench',
              // });
              this.$router.push('/mobile');
            } else {
              this.$router.push('/dashboard');
            }
            // if (data.position.value === 'general') {
            //   this.$router.push('/mobile');
            // } else {
            //   this.$router.push('/dashboard');
            // }
          } else {
            alert(data.info);
          }
        });
    },
  },
};
</script>
